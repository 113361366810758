import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { startLoading, endLoading } from "@/utils/function";
import API from "@/api/teacher/questionbank/questionList";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
        // 页面名称
        name: "questionList",
        inject:['reload'],
        data()
        {
            return{
                // 试题列表数据
                questionList:[],
                // 试题搜索信息
                searchInfo:"",
                // 选择的试题序号
                questionSelection:[],
                // 试题总数
                total:0,
                // 当前页码数
                currentPage:1
            }
        },
        /**
         * @name: 生命周期创建
         * @desc: 页面创建时获取数据
         * @author: lxy
         * @date: 20210908
         */
        created()
        {
            var self=this;
            // 获取试题列表数据
            self.currentPage=window.sessionStorage.getItem('pagenum')-0;
            self.getData();
            self.searchInfo="";
        },
        /**
         * @name: 生命周期挂载
         * @desc: 设置数据可拖拽
         * @author: lxy
         * @date: 20210924
         */
        mounted()
        {
            var self=this;
            self.rowDrop()
        },
        methods:
        {
            /**
             * @name: 返回上一级
            * @author: camellia
            * @date: 2021-01-15 14:00:30
            */
            goBack() {
                // this.$router.back()
                let self = this;
                self.$router.push({
                    path: "/teacher/questionBank",
                    query: {
                        // article_id: self.article_id,
                        // name:self.categoryName,
                        hideMenu:0
                    }
                }).then((res)=>{
                    self.reload()
                    // console.log(res);
                })            
            },
            /**
            * @name:添加试题
            * @author: lxy
            * @date: 2021-09-08
            */
            addQuestion() {
                let self = this;
                self.$router.push({
                    path: "/teacher/questionBankUpdate",
                    query: {
                        bank_id: self.$route.query.bank_id,
                        name:self.$route.query.name,
                        hideMenu:1,
                        sign:'add',
                        type:1
                    }
                }).then((res)=>{
                    self.reload()
                    // console.log(res);
                })            
                // window.open("#/teacher/questionBankUpdate?bank_id="+
                // self.$route.query.bank_id + 
                // '&hideMenu='+ 1 + 
                // '&sign=add' + 
                // '&name=' + 
                // self.$route.query.name)
            },
            /**
             * @name: 实现表格拖拽排序
             * @author: lanxinyu
             * @date: 2021-09-24
             */
            rowDrop() 
            {
                const self = this;
                const tbody = document.querySelector(".el-table__body-wrapper tbody");
                // Sortable.create(tbody);
                const sortable = new self.Sortable(tbody, {
                    onEnd: (evt) => { // 监听拖动结束事件
                        // console.log(evt.oldIndex) // 当前行的被拖拽前的顺序
                        // console.log(evt.newIndex) // 当前行的被拖拽后的顺序
                
                        let data={
                            oldIndex:evt.oldIndex,
                            newIndex:evt.newIndex
                  }
                }
              })
            },
            
            /**
             * @name: 分页获取试题
             * @desc: 根据页码获取试题
             * @author: lxy
             * @date: 20210908
             */
            handleCurrentChange(index)
            {
                let self = this;
                window.sessionStorage.setItem('pagenum',index);
                self.currentPage=window.sessionStorage.getItem('pagenum');
                let data = {
                    bank_id: self.$route.query.bank_id,
                    pagenum:self.currentPage,
                    searchInfo:self.searchInfo
                };
                nprogressStart();
                API.getQuestionBank(data)
                .then((result)=>{
                    if(result.code > 0)
                    {
                        if (result.questionList == "") 
                        {
                            self.questionList=[];
                        } 
                        else
                        {
                            result.questionList.forEach(item=>{
                                item.defaultMsg=item.defaultMsg.replace(/<.*?>/ig,"");
                                switch (item.questionType)
                                {
                                    case 'judge' :
                                        item.questionType='判断题';
                                        break;
                                    case 'radio' :
                                        item.questionType='单选题';
                                        break;
                                    default :
                                        item.questionType='多选题';
                                };
                                if(item.questionType=='判断题')
                                {
                                    item.rightAnswer=item.judge_right_key;
                                    return;
                                };
                                item.optionList.forEach(item1=>{
                                    if(item1.index==item.radio_right_key||item.judge_right_key)
                                    {
                                        item.rightAnswer=item.radio_right_key+':'+item1.content;
                                    }
                                });
                            });
                            self.total= result.total;
                            self.questionList=result.questionList;
                        };
                    }
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
                .catch(()=>{
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
            },

            /**
             * @name: 删除选择的试题
             * @desc: 根据试题ID数组删除试题
             * @author: lxy
             * @date: 20210908
             */
            handleDeleteQuestion()
            {
                var self=this;
                let data = {
                    exam_id: self.questionSelection
                }
                if(self.questionSelection==0)
                {
                //    return Message.info('至少选择一道题目');
                    return this.$Tips({
                        // 消息提示内容
                        message:"至少选择一道题目",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType:'warning',
                        button:[
                            {
                                type:1,
                                text:'知道了'
                            }
                        ]
                    })
                }
                try {
                    self.$confirm('确认删除该试题吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        }).then(() => {
                            // 开启loading
                            nprogressStart();
                            self.$Loading.show();
                            API.delQuestion(data)
                                .then((result)=>{
                                    if(result.code > 0)
                                    {
                                        self.getData();
                                    }
                                    nprogressClose();
                                    self.$Loading.hide();
                                    this.$Tips({
                                        // 消息提示内容
                                        message:result.msg,
                                        // 消息提示类型（success-成功,warning-警告/失败）
                                        messageType:'success',
                                        displayTime:1500
                                    })
                                })
                                .catch(()=>{
                                    // 关闭loading
                                    nprogressClose();
                                    self.$Loading.hide();
                                })
                            if(self.questionSelection.length==self.questionList.length)
                            {
                                self.questionList=[];
                                self.total=self.total-self.questionSelection.length;
                            } 
                            nprogressClose();
                            self.$Loading.hide();
                        }).catch(() => {
                            nprogressClose();
                            self.$Loading.hide();    
                        });
                } catch (error) {
                    nprogressClose();
                    self.$Loading.hide();    
                }
            },

            /**
             * @name: 删除试题
             * @desc: 根据试题ID删除试题
             * @author: lxy
             * @date: 20210908
             */
            handleDeleteOneQuestion(exam_id) 
            {
                const self = this;
                try {
                    self.$confirm('确认删除该试题吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        }).then(() => {
                            // 请求数据
                            self.questionSelection = [];
                            self.questionSelection.push(exam_id);
                            // 开启loading
                            let data = {
                                exam_id: self.questionSelection,
                            }
                            nprogressStart();
                            self.$Loading.show();
                            API.delQuestion(data)
                                .then((result)=>{
                                    if(result.code > 0)
                                    {
                                        // Message.success(result.msg);
                                        self.getData();
                                        this.$Tips({
                                            // 消息提示内容
                                            message:result.msg,
                                            // 消息提示类型（success-成功,warning-警告/失败）
                                            messageType:'success',
                                            displayTime:1500
                                        })
                                    }
                                    // 关闭loading
                                    nprogressClose();
                                    self.$Loading.hide();
                                })
                                .catch(()=>{
                                    // 关闭loading
                                    nprogressClose();
                                    self.$Loading.hide();
                                })
                            self.$Loading.hide();
                        }).catch((err) => {
                            nprogressClose();
                            self.$Loading.hide();

                        });
                } catch (error) {
                    nprogressClose();
                    self.$Loading.hide();
                };
            },

            /**
             * @name: 查询试题
             * @desc: 根据查询参数获取试题
             * @author: lxy
             * @date: 20210908
             */
            handleSearchQuestion()
            {
                var self=this;
                self.currentPage=1;
                self.$route.query.searchInfo=self.searchInfo;
                let data = {
                    bank_id: self.$route.query.bank_id,
                    searchInfo:self.searchInfo?self.searchInfo:self.$route.query.searchInfo
                };
                nprogressStart();
                // 请求数据
                // try {
                    API.getQuestionBank(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            if (result.data.questionList == "")
                            {
                            self.questionList=[];
                            } 
                            else 
                            {
                                result.data.questionList.forEach(item=>{
                                    item.defaultMsg=item.defaultMsg.replace(/<.*?>/ig,"");
                                    switch (item.questionType)
                                    {
                                    case 'judge' :
                                        item.questionType='判断题';
                                        break;
                                    case 'radio' :
                                        item.questionType='单选题';
                                        break;
                                    default :
                                        item.questionType='多选题';
                                }
                                if(item.questionType=='判断题')
                                {
                                    item.rightAnswer=item.judge_right_key;
                                    return;
                                }
                                    item.optionList.forEach(item1=>{
                                        if(item1.index==item.radio_right_key||item.judge_right_key)
                                        {
                                            item.rightAnswer=item.radio_right_key+':'+item1.content;
                                        };
                                    });
                                });
                                self.total= result.data.total;
                                self.questionList=result.data.questionList;
                                if( self.questionList.length<10)
                                {
                                    self.total = self.questionList.length;
                                }
                            }
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            },

            /**
             * @name: 修改某个试题详情
             * @desc: 根据试卷ID获取某个试题详情
             * @author: lxy
             * @date: 20210908
             */
            handleUpdateOneQuestion(exam_id)
            {
                try {
                    var self=this;
                    self.questionSelection=[];
                    self.questionSelection.push(exam_id);
                    const bank_id=self.$route.query.bank_id;
                    router.push({
                            path: "/teacher/questionBankUpdate",
                            query: {
                                    bank_id: bank_id,
                                    sign:'update',
                                    questionSelection:self.questionSelection.toString(),
                                    searchInfo:self.searchInfo,
                                    name:self.$route.query.name
                                    }
                    });
                } catch (error) {
                    // Message.error('网络错误');
                }
                
            },

            /**
             * @name: 修改选择的试题详情
             * @desc: 根据试卷ID数组获取试题详情
             * @author: lxy
             * @date: 20210908
             */
            handleUpdateQuestion()
            {
                try {
                    var self=this;
                    if(self.questionSelection.length==0)
                    {
                        // return self.$message.info('请至少选择一道题目');
                        return this.$Tips({
                            // 消息提示内容
                            message:"请至少选择一道题目",
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'warning',
                            button:[
                                {
                                    type:1,
                                    text:'知道了'
                                }
                            ]
                        })
                    }
                    else if(self.questionSelection.length>10)
                    {
                        // return self.$message.info('最多选择10道题目');
                        return this.$Tips({
                            // 消息提示内容
                            message:"最多选择10道题目",
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'warning',
                            button:[
                                {
                                    type:1,
                                    text:'知道了'
                                }
                            ]
                        })
                    }
                    const bank_id=self.$route.query.bank_id;
                    router.push({
                        path: "/teacher/questionBankUpdate",
                        query: {
                            bank_id: bank_id,
                            sign:'update',
                            questionSelection:self.questionSelection.toString(),
                            searchInfo:self.searchInfo,
                            hideMenu:1,
                            name:self.$route.query.name,
                            type:1
                        }
                    });
                } catch (error) {
                    // Message.error('网络错误');
                }
            },

            /**
             * @name: 获取选择的试题序号
             * @desc: 根据试卷ID获取试题列表
             * @author: lxy
             * @date: 20210908
             */
            handleSelectionChange(question) 
            {
                try {
                    var self=this;
                    var questionSelection=[];
                    question.forEach(item=>{
                        questionSelection.push(item.exam_id);
                    });
                    self.questionSelection = questionSelection;
                } catch (error) {
                    // Message.error('网络错误');
                }
            },

            /**
             * @name: 获取试题列表数据
             * @desc: 根据试卷ID获取试题列表
             * @author: lxy
             * @date: 20210908
             */
            getData()
            {
                // try {
                    const self = this;
                    let data = {
                        bank_id: self.$route.query.bank_id,
                        searchInfo: self.$route.query.searchInfo,
                        pagenum: self.currentPage,
                    };
                    // 开启loading
                    nprogressStart();
                    self.$Loading.show();
                    // 请求数据
                    API.getQuestionBank(data)
                        .then((result)=>{
                            if(result.code > 0)
                            {
                                self.questionList = [];
                                if (result.questionList) 
                                {                                
                                    for(var item in result.questionList)
                                    {
                                        switch (result.questionList[item].questionType) 
                                        {
                                            case "judge":
                                                result.questionList[item].questionType = "判断题";break;
                                            case "radio":
                                                result.questionList[item].questionType = "单选题";break;
                                            default:
                                                result.questionList[item].questionType = "多选题";
                                        }
                                        self.questionList.push(
                                            result.questionList[item]
                                        );
                                    }
                                    self.total = result.total;
                                }
                            }
                            // 关闭loading
                            nprogressClose();
                            self.$Loading.hide();
                        })
                        .catch(()=>{
                            // 关闭loading
                            nprogressClose();
                            self.$Loading.hide();
                        })
                    
                    self.$Loading.hide();
            },
        }       
};

