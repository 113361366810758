<template >
    <div class="questionList">
        <el-card>
            <!-- 修改/删除多道题目 -->
            <div class="handleButton">
                <el-button class="themeButton" @click="addQuestion">添加试题</el-button>
                <el-button class="themeButton" @click="handleUpdateQuestion">编辑</el-button>
                <el-button class="deleteButton" @click="handleDeleteQuestion">删除</el-button>
            </div>
            <!-- 搜索题目 -->
            <!-- <div class="search">
            <el-input placeholder="请输入内容" v-model="searchInfo" class="input-with-select"></el-input>
            <el-button type="primary" @click="handleSearchQuestion">搜索</el-button>
        </div>  -->
            <div class="expSearch">
                <el-input clearable placeholder="请输入试卷名称" v-model="searchInfo" @clear="handleSearchQuestion">
                    <el-button slot="append" class="el-icon-search" @click="handleSearchQuestion"></el-button>
                </el-input>
            </div>
            <!-- 题目列表 -->
            <el-table ref="multipleTable" :data="questionList" tooltip-effect="dark" style="width: 100%"
                @selection-change="handleSelectionChange">
                <!-- 多选框 -->
                <el-table-column type="selection" width="55">
                </el-table-column>
                <!-- 题目序号 -->
                <el-table-column type="index" label="序号" width="80">
                    <template slot-scope="scope">
                        <span>{{ (currentPage - 1) * 10 + scope.$index + 1 }}</span>
                    </template>
                </el-table-column>
                <!-- 题目名称 -->
                <el-table-column prop="defaultMsg" label="题目">
                    <template slot-scope="scope">
                        <span v-html="scope.row.defaultMsg"></span>
                    </template>
                </el-table-column>
                <!-- 题目类型 -->
                <el-table-column prop="questionType" label="类型" width="120">
                </el-table-column>
                <!-- 题目正确答案 -->
                <!-- <el-table-column
                      prop="rightAnswer"
                      label="正确答案"
                      width="120"
                      >
                    </el-table-column> -->
                <!-- 题目分值 -->
                <el-table-column prop="questionScore" label="分数" width="120">
                </el-table-column>
                <!-- 题目分值 -->
                <el-table-column prop="accuracy_rate" label="准确率（%）" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.accuracy_rate">{{scope.row.accuracy_rate}}%</span>
                        <span v-else> - </span>
                    </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <div class="operateItem">
                            <div class="operatorItem floatLeft" @click="handleUpdateOneQuestion(scope.row.exam_id)">修改</div>
                            <div class="operatorItem floatLeft" @click="handleDeleteOneQuestion(scope.row.exam_id)">删除</div>
                        </div>
                        <!-- <el-button type="primary" plain @click="handleUpdateOneQuestion(scope.row.exam_id)">修改</el-button>
                            <el-button type="primary" plain @click="handleDeleteOneQuestion(scope.row.exam_id)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 题目分页 -->
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" background
                layout="prev, pager, next" :total="total">
            </el-pagination>
        </el-card>
        <div class="backFix">
            <el-button class="themecancelButton" @click="goBack()">返回</el-button>
            <!-- <el-button type="primary" @click="execUpdateArticle()"
                >提&nbsp;&nbsp;交</el-button
            > -->
        </div>
    </div>
</template>
<script>
// 引入试题列表js
import questionList from "@/assets/js/teacher/questionbank/questionList.js";
export default {
    ...questionList
}
</script>
<style >
.questionList .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D5570;
    color: #FFF;
}

.questionList .el-table__row {
    height: 4.2vw;

}

.questionList .expSearch .el-input__inner {
    height: 100% !important;
    line-height: 100% !important;
}

.questionList .expSearch .el-input {
    height: 100%;
}

.questionList .el-table {
    font: normal normal normal 0.8333vw Source Han Sans CN !important;
}

.questionList .cell p {
    font: normal normal normal 0.8333vw Source Han Sans CN !important;
}

.questionList .el-table .el-table__body-wrapper {
    height: 70vh;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.questionList .el-table .el-table__body-wrapper::-webkit-scrollbar {
    display: none;
}

/* .el-button--primary{
    background-color: #0D5570 !important;
    border-color: #0D5570 !important;
} */
.questionList .el-button--primary,
.questionList .el-checkbox__input.is-checked .el-checkbox__inner,
.questionList .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #0D5570 !important;
    border-color: #0D5570 !important;
}

.questionList .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #0D5570 !important;
}
</style>
<style lang="scss" scoped>
// 引入试题列表组件css
@import "@/assets/css/teacher/questionbank/questionList.scss";
</style>