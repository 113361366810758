// 进度条
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

/**
 * @name: 全局进度条的配置
 * @author: camellia
 * @date: 2023-06-25
 */
NProgress.configure({ 
    easing: 'ease',  // 动画方式    
    speed: 1000,  // 递增进度条的速度    
    showSpinner: true, // 是否显示加载ico    
    trickleSpeed: 200, // 自动递增间隔    
    minimum: 0.3 // 初始化时的最小百分比
})

/**
 * @name: 打开进度条
 * @author: camellia
 * @date: 2023-06-25
 */
export const nprogressStart = ()=>{
    NProgress.start()
}

/**
 * @name: 关闭进度条
 * @author: camellia
 * @date: 2023-06-25
 */
export const nprogressClose = ()=>{
    NProgress.done()
}